@import '../../styles/common/common.scss';

.paragraph {
    font-family: var(--utmb-font-futura-book);
    font-weight: normal;
    font-style: normal;
    font-size: rem(18px);
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    a {
        text-decoration: underline;
        text-underline-offset: rem(2px);
        text-decoration-thickness: rem(2px);
        font-weight: bold;
    }
    strong {
        font-weight: bold;
    }
    i {
        font-style: italic;
    }
}

.section_block {
    padding: var(--component-unhook-height) rem(40px);

    @include bp(lg) {
        margin-top: calc(var(--component-unhook-height) * 2);
        margin-bottom: calc(var(--component-unhook-height) * 2);
        padding-top: 0;
        padding-bottom: 0;
    }

    h2 {
        margin-bottom: 1rem;
    }
    h2 + p {
        margin-bottom: 2rem;
    }
}

.is_dark {
    color: var(--utmb-color-white);
}

.link {
    display: flex !important;
    align-items: center;
    margin-bottom: rem(32px);
    text-underline-offset: rem(12px);
    text-decoration-thickness: 1.5px;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        text-underline-offset: rem(12px);
        text-decoration-thickness: rem(2px);
        font-weight: 800 !important;
    }
}

.button {
    box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.3);
    text-decoration: none;
    margin-bottom: rem(32px);
    max-width: rem(300px);
    align-self: center;

    @include bp(md) {
        align-self: unset;
    }

    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none !important;
    }

    &:hover {
        box-shadow: 0 0 rem(15px) rgba(0, 0, 0, 0.5);
        filter: brightness(1.2);
    }

    &:last-child {
        margin-bottom: 0;
    }
}
